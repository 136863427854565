.quote {
  font-family: Arial, sans-serif;
  margin: 20px;
}

h1, h2 {
  color: #333;
}

p {
  font-size: 1.2em;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
}

/* label {
  display: block;
  margin-top: 10px;
} */

select {
  margin-left: 10px;
  padding: 5px;
  font-size: 1em;
}

button {
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  margin-top: 20px;
  margin-right: 10px;
}

/* input[type="text"] {
  margin-left: 10px;
  padding: 5px;
  font-size: 1em;
} */
