.dashboard-container {
    padding: 20px;
  }
  
  .estimator-buttons {
    margin-top: 20px;
  }
  
  .action-button {
    margin-right: 10px;
    margin-top: 10px;
  }
  
  .role-button {
    margin-right: 10px;
    margin-top: 5px;
  }
  
  .separator {
    margin: 20px 0;
  }
  
  .business-roles {
    margin-top: 20px;
  }
  